import * as React from "react";
import Head from "next/head";
import Image from "next/image";

// Import required MUI layout/components
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

import mainTheme from "./mainTheme";
import navStyles from "../styles/Navigation.module.css";

const MainFooter = () => {
  return (
    <ThemeProvider theme={mainTheme}>
      <div style={mainTheme.custom.navigationBottom}>
        <Container maxWidth="lg">
          <div className={navStyles.footerContainer}>
            <div className={navStyles.footerLogo}>
              <Link href="/">
                <Image src="/es_icon_white.svg" alt="Elm Street Icon" width={201} height={201} />
              </Link>
            </div>
            <div className={navStyles.footerLinkSection}>
              <div className={navStyles.footerSubContainer}>
                <div className={navStyles.footerColumn}>
                  <div className={navStyles.footerColumnHeader}>
                    <Link className={navStyles.footerColumnHeader} style={{ color: "#f69c26" }} href="/">
                      Solutions
                    </Link>
                  </div>
                  <div>
                    <div>
                      <Link href="/idx-broker">
                        <span style={{ color: "#ffffff", textDecoration: "none" }}>IDX Broker</span>
                      </Link>
                    </div>
                    <div>
                      <Link href="/ixact-contact">
                        <span style={{ color: "#ffffff", textDecoration: "none" }}>IXACT Contact</span>
                      </Link>
                    </div>
                    <div>
                      <Link href="morris-marketing-group">
                        <span style={{ color: "#ffffff", textDecoration: "none" }}>Morris Marketing Group</span>
                      </Link>
                    </div>
                    <div>
                      <Link href="/outbound-engine">
                        <span style={{ color: "#ffffff", textDecoration: "none" }}>OutboundEngine</span>
                      </Link>
                    </div>
                    <div>
                      <Link href="/aiva">
                        <span style={{ color: "#ffffff", textDecoration: "none" }}>Aiva</span>
                      </Link>
                    </div>
                    <div>
                      <Link href="/voicepad">
                        <span style={{ color: "#ffffff", textDecoration: "none" }}>VoicePad</span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className={navStyles.footerColumn}>
                  <div className={navStyles.footerColumnHeader}>
                    <Link className={navStyles.footerColumnHeader} style={{ color: "#f69c26" }} href="/">
                      Resources
                    </Link>
                  </div>
                  <div>
                    <div>
                      <Link href="/blog">
                        <span style={{ color: "#ffffff", textDecoration: "none" }}>Blog</span>
                      </Link>
                    </div>
                    <div>
                      <Link href="/educational_guides">
                        <span style={{ color: "#ffffff", textDecoration: "none" }}>Educational Guides</span>
                      </Link>
                    </div>
                    <div>
                      <Link href="/ourclients">
                        <span style={{ color: "#ffffff", textDecoration: "none" }}>Testimonials</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className={navStyles.footerColumn}>
                  <div className={navStyles.footerColumnHeader}>
                    <Link className={navStyles.footerColumnHeader} style={{ color: "#F69c26" }} href="/">
                      Company
                    </Link>
                  </div>
                  <div>
                    <div>
                      <Link href="/leadership">
                        <span style={{ color: "#ffffff", textDecoration: "none" }}>Leadership</span>
                      </Link>
                    </div>
                    <div>
                      <Link href="mailto:hello@elmstreet.com">
                        <span style={{ color: "#ffffff", textDecoration: "none" }}>Email Us</span>
                      </Link>
                    </div>
                    <div>
                      <Link href="https://www.linkedin.com/company/elm-street-technology/jobs/" target="_blank">
                        <span style={{ color: "#ffffff", textDecoration: "none" }}>Careers</span>
                      </Link>
                    </div>
                    <div>
                      <Link href="/blog?p=1&c=Company%20News">
                        <span style={{ color: "#ffffff", textDecoration: "none" }}>Press</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className={navStyles.footerColumn}>
                  <div>
                    <div className={navStyles.footerColumnHeader}>
                      Let&apos;s <span style={{ color: mainTheme.palette.secondary.main }}>Get Social</span>
                    </div>
                    <div className={navStyles.footerSocialIcons}>
                      <div>
                        <Link href="https://www.facebook.com/WeAreElmStreet" target="_blank">
                          <Image src="/footer_fb.svg" alt="Elm Street Icon" width={42} height={42} />
                        </Link>
                      </div>
                      <div>
                        <Link href="https://www.instagram.com/weareelmstreet/" target="_blank">
                          <Image src="/footer_insta.svg" alt="Elm Street Icon" width={42} height={42} />
                        </Link>
                      </div>
                      <div>
                        <Link href="https://www.linkedin.com/company/elm-street-technology/" target="_blank">
                          <Image src="/footer_li.svg" alt="Elm Street Icon" width={42} height={42} />
                        </Link>
                      </div>
                      <div>
                        <Link href="https://twitter.com/weareelmstreet" target="_blank">
                          <Image src="/footer_x.svg" alt="Elm Street Icon" width={42} height={42} />
                        </Link>
                      </div>
                      <div>
                        <Link href="https://www.youtube.com/channel/UCKVavElvMaRgAHdpMXF8O9A" target="_blank">
                          <Image src="/footer_yt.svg" alt="Elm Street Icon" width={42} height={42} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={navStyles.footerSubLine} style={{ fontSize: "11px", paddingTop: "15px" }}>
                ©2025 Elm Street Technology / <Link href="/privacy">Privacy</Link> / <Link href="/terms">Terms</Link> /{" "}
                <Link href="/dmca">DCMA Notice</Link>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default MainFooter;
